@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input,
[data-tip] {
  cursor: pointer;
}

.header-button-white::after,
.header-button-black::after {
  position: absolute;
  content: '';
  width: 0;
  height: 1px;
  top: 100%;
  left: 0;
  transition: width 0.25s;
}

.header-button-white::after {
  background: white;
}

.header-button-black::after {
  background: black;
}

.header-button-white:hover::after,
.header-button-black:hover::after {
  width: 100%;
}

/* override built-in swiper-container z-index */
.swiper-container.swiper-container {
  z-index: 0;
}

.embed-youtube-player {
  padding-bottom: 56.25%;
}

/* backyard */
@media print {
  #__next {
    display: none;
  }

  iframe {
    page-break-after: always;
    -webkit-print-color-adjust: exact !important;
  }
}

input[type='checkbox'][name='ads-image-selection'] + .chakra-checkbox__control,
input[type='radio'][name='ads-image-selection'] + .chakra-radio__control {
  display: none;
}

input[name='ads-image-selection'] ~ .chakra-checkbox__label {
  border: 3px solid #fff;
}

input[name='ads-image-selection']:checked ~ .chakra-checkbox__label {
  border-color: #fd6d59;
}

.funwoo-select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.funwoo-select::-ms-expand {
  display: none;
}

select:focus {
  outline: none !important;
  border: none;
  box-shadow: none !important;
}

select.chakra-select {
  border: 1px solid #e0e0e0;
  padding-left: 0;
}

/**
 * Toast
 */
.Toastify {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.Toastify .funwoo-toast {
  text-align: justify;
  opacity: 0.85;
}

.Toastify .funwoo-toast.Toastify__toast-container {
  max-width: 50rem;
}

.backyard .Toastify .funwoo-toast.Toastify__toast-container {
  width: 50%;
}

.Toastify .funwoo-toast .Toastify__toast {
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
  font-size: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 55px;
}

.Toastify .entrance-toast.entrance-mobile-toast.Toastify__toast-container {
  margin: 0 auto 20px 0;
  padding: 0 20px;
}

.Toastify .funwoo-toast .Toastify__toast--info,
.Toastify .funwoo-toast .Toastify__toast--success,
.Toastify .funwoo-toast .Toastify__toast--warning,
.Toastify .funwoo-toast .Toastify__toast--error {
  padding: 1rem;
  color: white;
}

.Toastify .funwoo-toast .Toastify__toast--info,
.Toastify .funwoo-toast .Toastify__toast--success {
  background: black;
}

.Toastify .funwoo-toast .Toastify__toast--warning,
.Toastify .funwoo-toast .Toastify__toast--error {
  background: #e50914;
}

.animated-subnav::-webkit-scrollbar {
  display: none;
}

.async-crop-image-modal__cropper > div:first-child {
  height: 100%;
  background-color: black;
}

input:active,
input:focus,
input:focus-visible,
button:active,
button:focus,
button:focus-visible,
button {
  outline: none !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
}

.chakra-switch__track {
  background: #e0e0e0;
}

.chakra-switch__track[data-checked] {
  background: black;
}

.RaLayout-appFrame {
  width: 100vw;
  height: 100vh;
  margin-top: 0 !important;
  padding-top: 3rem !important;
}

.RaLayout-contentWithSidebar {
  overflow: hidden;
  flex: 1 1 0;
}

.MuiDrawer-root {
  overflow: scroll;
}

.RaLayout-content {
  overflow: scroll;
  flex: 1 1 0;
}
